<template>
  <div class="landing" style="text-align: center">
    <br>
    <h1 class="title">Easily Build your own <strong>optimized</strong> Discord Bot.</h1>
    <br>
    <p class="title-text" >MelisaPy is a Discord microframework for Python 3 for the Discord API.
      <br>
     We provide really optimized and cool cache configuration, so don't worry about the RAM :)
    </p>
    <br>
    <button style="background-color: #06b783" class="main-button">
      <a href="https://github.com/MelisaDev/melisa#readme">Read More</a></button>
    <button style="background-color: #e3dfdf; color: black" class="main-button"><a href="https://github.com/MelisaDev">Github</a></button>
    <br>
    <p class="question-q text-center">- And is it easy to use?</p>
    <p class="question-a">- Yes it is!
    </p>
    <figure class="window" style="
    text-align: left!important;
    display: block;
    margin-left: auto;
    margin-right: auto">
      <figcaption class="window-header">
        <p>main.py</p>
        <svg
            width="36"
            height="8"
            viewBox="0 0 36 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="32" cy="4" rx="4" ry="4" fill="#DC5252" />
          <ellipse cx="18" cy="4" rx="4" ry="4" fill="#D6985E" />
          <ellipse cx="4" cy="4" rx="4" ry="4" fill="#8ECF4E" />
        </svg>
      </figcaption>
      <highlightjs
          style="margin-top: 0"
          language="python"
          :code="simple_code"
      />
    </figure>
    <br>
    <div class="container flex justify-center mx-auto pt-16" id="team">
      <div>
        <h2 class="core-title text-center">Meet our core team</h2>
        <h2 class="text-center core-subtitle">We are just people who want to make this world better and easier.</h2>
      </div>
    </div>
    <div class="w-full px-10 pt-10">
      <div class="container mx-auto">
        <div role="list" aria-label="Behind the scenes People " class="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
          <div role="listitem" class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div class="rounded overflow-hidden shadow-md bg-white dark:bg-gray-900 dev-card">
              <div class="absolute -mt-20 w-full flex justify-center">
                <div class="h-32 w-32">
                  <img src="https://avatars.githubusercontent.com/u/61203964?v=4" alt="Display Picture of Marakarka" role="img" class="rounded-full object-cover h-full w-full shadow-md" />
                </div>
              </div>
              <div class="px-6 mt-16 dev-card">
                <h1 class="font-bold dark:text-white text-3xl text-center mb-1 devCard-name">Marakarka</h1>
                <p class=" text-center devCard-badge">Founder</p>
                <p class="text-center dark:text-gray-200 text-base pt-3 font-normal devCard-text">Just a student who really likes to create things and I really enjoy learning new things to become a better developer. (I am FullStack Web Developer and also Python and Rust developer.)</p>
                <div class="w-full flex justify-center pt-5 pb-5">
                  <a href="https://github.com/grey-cat-1908" class="mx-5">
                    <div aria-label="Github" role="img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#718096" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github">
                        <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"/>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div role="listitem" class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div class="rounded overflow-hidden shadow-md bg-white dark:bg-gray-900 dev-card">
              <div class="absolute -mt-20 w-full flex justify-center">
                <div class="h-32 w-32">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASYSURBVHgB1ZrdSmNXFMf/+ySNH1WMIFoF5WApDt5on6BpL8rcNd710j5BLRQKpWACpaVQaOYN7BNMelfmohOfYNK7EVp6QBAELwKCEm2yZ/1PsjPHmJOcvU8mzvzgqIk7J2vt9blXojAGCoViHtMooo0dD/C1wq48ne9ehkDLBY06PLmA49qf1QApUXCEQnvTONAan/AhXBBltIcKUihjrUBE8K9xd4dTIYIctRXKtopYKfDZ42Jp3IL3oztKlJKuT6RA4XHRV208Rce3J0EginyaxBreqAUi/L7SeDFB4Qk37EXh82Jx1MLMsH/SZSTQfpM/pzFpFKaVwpf+R49U8M/LWvyyGLr+fghL5ufnsbi4iIWFBUxNTYXPXV1d4fr6GmdnZ2g2m7BlWFwMVICmE+2fwoJsNovV1VWsrKwMXXd+fh4q0mq1YIMosS9K/N7//D0FwoClz1tkGgq/tbWFmZmZROtpkZOTEzslNBpSMz7uD+x7QSzCP4dlmuTOJxWezM7OYm1tDVYo5MNM2MedIA6DFhgZ+VHo55ubm7Blbm4Ol5eXuLm5Sf4ihQ/8Dx8h+PflsXmqZwG6jkvQWu9khHzevh6Kzx+EvVeXngKeg/DExnX6cVGAroRc2A2EhAqEuw/swwH6sysmzdoStYKxQAHvEhErhAoo/doktrgUJgPTqTOqs+mqm/f/gyPr6+sji1ccFxcXCIIArugmFmmBAlLQaDTgCityKnL4wpMKt4MUMJezPbCFr7GqAYPZ9SSiU7fJ3Ekbf+ba1LvfwacL+UgJexr2NkkswTXWfVAM0nDuZjEGBQgFOj09DQVkdWZ9MDWCmYqxwosuNzY08lmMGfp1msxihdSDkUfKtx0q4J4H3wLoQlQg9ZiER0le9Hs2eNE+hzFA16L/m2tMBFlJo3XtGMg8iS0vL4eVOJOJnw9QGV5UkFAZplFW4jRwVJltyw+X+SIP7mwjcrkcbOFrfN8Ps1UqRWQ0mZWB7N+wCOWkh/ckGEXockzBDtQ9vI9q0tXm8D4O4aPwftvb20PdcCAejr1atcogriVZbzN5sIXBz/snVkLchxMKcx6ojVpPf39TwhtsphUSwBzLd7y/PYsnGFIPlpaWxu42cfB9Ep2VxX06vwS6kVihErc2zeTBhY2NjaGuJLt/ZAZcvfwTZwXuvkuqTAPfb6jFZVZq/uwpEGeFSe++IU4BmV2VouPFOxXgr2dValY3j1k5J737BrqQqdwRglpHxh73SphMgffQdaUBN5gofe/f4Kc2/WvuKUDzyMJvBtxg4kSHZuI6Xw36yGlgEyELjyQeSg/lPgZTd0K/f1Yd2DHEdkGMh1xuqoQHhB1sV/hy3JqhbdyvP/1Qbiu9J2nrIQ49jTb0/jDhSaJO+uC7n/33Mu3n0nP4mATS59y2bvcqv5SDUUutjgLffv/joYwySnhzNMRlKrR80hdYn2W61jgUa+xjfISC/391+6RSKVu5q/OXPaiIl2kXMkpzsu003dNs46UTdhHc4KxAFKOMp/WOpIVdpZXfFy8NqZANfs1Gt7T08F795rr5h6vQUV4BA7PEMiPZLGQAAAAASUVORK5CYII=" alt="Display Picture of NO" role="img" class="rounded-full object-cover h-full w-full shadow-md" />
                </div>
              </div>
              <div class="px-6 mt-16 dev-card">
                <h1 class="font-bold dark:text-white text-3xl text-center mb-1 devCard-name">Maybe you?</h1>
                <p class="text-center devCard-badge">Maybe Maintainer?</p>
                <p class="text-center dark:text-gray-200 text-base pt-3 font-normal devCard-text">Do you want to become a part of our team?</p>
                <div class="w-full flex justify-center pt-5 pb-5">
                  <button style="background-color: #06b783  " class="main-button">
                    <a href="https://discord.com/invite/QX4EG8f7aD">Contact Us Here</a></button>
                </div>
              </div>
            </div>
          </div>
          <div role="listitem" class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div class="rounded overflow-hidden shadow-md bg-white dark:bg-gray-900 dev-card">
              <div class="absolute -mt-20 w-full flex justify-center">
                <div class="h-32 w-32">
                  <img src="https://avatars.githubusercontent.com/u/61903983?v=4" alt="Display Picture of TheMisterSenpai" role="img" class="rounded-full object-cover h-full w-full shadow-md" />
                </div>
              </div>
              <div class="px-6 mt-16">
                <h1 class="font-bold dark:text-white text-3xl text-center mb-1 devCard-name">TheMisterSenpai</h1>
                <p class=" text-center devCard-badge">Co-Founder</p>
                <p class="text-center dark:text-gray-200 text-base pt-3 font-normal devCard-text">Lead developer of the Discord bot "Мая" and co-founder project Melisa. I know python and some node.js. Learning Go. </p>
                <div class="w-full flex justify-center pt-5 pb-5">
                  <a href="https://github.com/TheMisterSenpai" class="mx-5">
                    <div aria-label="Github" role="img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#718096" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github">
                        <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"/>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import hljs from 'highlight.js/lib/common';
import hljsVuePlugin from "@highlightjs/vue-plugin";

export default {
  name: "MainContent",
  components: {
    highlightjs: hljsVuePlugin.component
  },
  data () {
    return {
      simple_code: `import melisa

client = melisa.Client("your cool token...")

@client.listen
async def on_message_create(message):
    if message.content.startswith('!ping'):
        await message.channel.send(f'Pong!')

client.run_autosharded()`
    }
  }
}
</script>

<style lang="scss" scoped>
 * {
   box-sizing: border-box;
 }
@font-face {
  font-family: "Passion One";
  src: url(@/assets/fonts/PassionOne-Bold.ttf);
}

@font-face {
  font-family: "Ginto Nord";
  src: url(@/assets/fonts/Ginto-Nord-Black.ttf);
}

 @font-face {
   font-family: "Ginto";
   src: url(@/assets/fonts/Ginto-Black.ttf);
 }

@font-face {
  font-family: "Roboto";
  src: url(@/assets/fonts/Roboto-Bold.ttf);
}

 @font-face {
   font-family: "Roboto Light";
   src: url(@/assets/fonts/Roboto-Light.ttf);
 }

.main-button {
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  height: 3rem;
  margin: 0.5rem;
  transition: .3s ease-in-out;
}

.main-button:hover {
  transform: translateY(-2px);
}
.main-button:active {
  transform: translateY(-2px);
  transform: scale(0.95);
}

.title {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1.5px;
  font-size: 30px;
}

.title-text {

  font-size: 18px;
  padding: 10px;
  color: #c4c4c4;
  
}

.dev-card {
  background: #111827;
  height: 330px;
  color: white;
}

.devCard-name {
  font-family: "Roboto", cursive;
  letter-spacing: 1.5px;
  margin-bottom: 0.65em;;
}

.devCard-badge {
  margin: 10px 0;
  display: inline;
  padding: 5px 10px;
  border-radius: 10rem;
  background: #06b783;
}

@media (max-width:430px) {
  .dev-card {
    height: 350px;
  }
}

.question-q {
  margin-top: 25px;
  font-size: 25px;
}

.question-a {
  font-size: 18px;
}
.hero-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 720px) and (max-width: 979px) {
  .hero-left {
    gap: 40px;
  }
  .explore {
    min-width: 300px;
    &-container {
      display: flex;
      gap: 20px;
    }
  }
  .hero-right {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }


}

[aria-label="Github"] {
  padding: 7px 7px 6px 7px;
  border-radius: 100%;

  transition: .2s ease-in-out;
}
[aria-label="Github"]:hover {
  background: rgb(255, 255, 255, 0.1);
}

[aria-label="Github"]:active {
  background: rgb(255, 255, 255, 0.25);
}

.devCard-text {
  margin-top: 0.65em;
}

.window {
  padding: 0;
  width: calc(100vw - 40px);
  margin: 2em 0 0;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1c2431;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.33);
    padding: 10px 20px;
    border-radius: 0.2em 0.2em 0 0;
    p {
      margin: 0;
      font-size: 15px;
    }
  }
}
@media screen and (min-width: 720px) and (max-width: 979px) {
  .window {
    width: 100%;
  }
}
@media screen and (min-width: 980px) {
  .window {
    animation: slideInTopFade 1s ease;
    width: min(640px, 40vw);
  }
}

@media screen and (min-width: 980px) {
  .hero {
    margin-top: 20px;
    display: flex;
    gap: 64px;
    &-left {
      width: 100%;
      animation: slideInLeftFade 2s ease;
    }
    &-right {
      display: flex;
      flex-direction: column;
      width: 110%;
      gap: 0;
    }
  }
}

.core-title {
  font-size: 30px;
  color: gray;
}

.core-subtitle {
  font-size: 33px;
  width: 80%;
  margin: 0 auto;
}

</style>