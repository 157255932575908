import { createApp } from 'vue'
import App from './App.vue'

import 'highlight.js/styles/atom-one-dark-reasonable.css'
import 'highlight.js/lib/common';
import hljsVuePlugin from "@highlightjs/vue-plugin";

import './index.css'

createApp(App).use(hljsVuePlugin).mount('#app')
